@import './colors.scss';

#header{
     .header-logo{
        width:120px;
     }  

     .navbar-light .navbar-nav .nav-link{
        color: $secondary;
     }
}