#movements-form{
    .file-drop{
        min-height: 100px;
        background: #DDD;
        margin: 50px 0px;
        display:flex;
        align-items: center;
        justify-content: center;
        cursor:pointer;

        p{
            margin:0px;
        }
    }
}