@import "~bootstrap/scss/bootstrap";
@import './styles/colors.scss';
@import './styles/fonts.scss';

.row{
    margin:0px !important;
}

.page-body{
    padding:100px 0px;
    background: #DDD;
    min-height: 80vh;
}

.cursor-pointer{
    cursor:pointer;
}

a,p,span,ul,ol,h3,h4,h5,h6{
    font-family: 'Roboto';
}

p,a,ul,ol,a.btn,button,button.btn{
    font-size: 1.125em;
}

.file-drop{
    min-height: 100px;
    background: #DDD;
    margin: 50px 0px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;

    p{
        margin:0px;
    }
}

p{
    color: $black;
}

h1{
    font-size: 3.125em;
    font-family: 'RobotoBold';
    color: $primary;
}

.h5{
    color: $primary;
    font-family: 'RobotoBold';
}

h2{
    font-size: 1.875em;
    font-family: 'RobotoBold';
    color: $primary;
}

h3{
    font-size: 1.75em;
    font-family: 'RobotoBold';
}

.btn.btn-primary{
    background: $primary;
}