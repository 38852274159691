#login-container{
    min-height:80vh;
    background: #DDD;
    display:flex;
    align-items: center;

    .login-logo{
        width:300px;
        display:block;
        margin: 0 auto;
    }

    .h5{
        text-align:center;
    }
}